.root {
  margin-top: 8px;
  background-color: #fff;
}

.openTitle {
  font-weight: 600;
}

.space {
  width: 100%;
}

.actions {
  margin-left: -16px;
}

@media screen and (min-width: 640px) {
  .root {
    display: none;
  }
}
