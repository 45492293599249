.content {
  padding: 24px;
  background-color: #fff;
  flex: 1;
}

@media screen and (max-width: 640px) {
  .content {
    padding: 6px;
  }
}
