.table table {
  width: auto;
  min-width: unset !important;
}

.checkboxGroup {
  width: 100%;
  line-height: 32px;
}

.groups {
  margin-top: 24px;
}

.editButton {
  margin-bottom: 24px;
  margin-top: 13px;
}
