.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
}

.table table {
  width: auto;
  min-width: unset !important;
}

.modal {
  min-width: 450px;
}

.about {
  margin-bottom: 24px;
}

.addButton {
  margin-top: 24px;
}

.select {
  width: 100px;
}

@media screen and (max-width: 640px) {
  .modal {
    min-width: 350px;
    width: 100% !important;
  }
}