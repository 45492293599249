.mainMenu {
  flex-grow: 1;
  margin-left: 24px;
}

@media screen and (max-width: 640px) {
  .mainMenu {
    display: none;
  }
}
