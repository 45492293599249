.ant-input-number-handler-wrap {
  display: none;
}

.sorter-badge .ant-badge-count {
  margin-left: 8px;
  color: #999;
  background-color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.ant-menu-item-selected .sorter-badge .ant-badge-count {
  margin-left: 8px;
  color: #fff;
  background-color: #1890ff;
  box-shadow: 0 0 0 1px #fff inset;
}

.ant-menu-item-selected {
  font-weight: bold;
}

.lamps-badge .ant-badge-count {
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}