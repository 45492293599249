.mobileMenu {
  display: none;
}

@media screen and (max-width: 640px) {
  .mobileMenu {
    display: block;
    background-color: #f0f2f5;
    margin: 0 auto;
    min-width: 280px;
  }

  .mobileMenu li {
    padding: 0 10px !important;
  }
}
