.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
}

.table table {
  width: auto;
  min-width: unset !important;
}

.radioGroup {
  width: 100%;
  line-height: 32px;
  padding-top: 6px;
}

.delText {
  margin-top: 24px;
}

.delButton {
  color: red;
}

.switch {
  margin-right: 10px;
  max-width: 44px;
}

.control {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
}

@media screen and (max-width: 640px) {
  .table {
    display: none;
  }
}
