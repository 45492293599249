.dimm {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slider {
  min-width: 100px;
  max-width: 200px;
  flex-grow: 1;
  margin-left: 20px;
}

.inputNumber {
  width: 50px;
}

.percent {
  padding-left: 5px;
}

.noDimm {
  color: grey;
}

@media screen and (max-width: 640px) {
  .inputNumber {
    display: none;
  }
  .percent {
    display: none;
  }
  .slider {
    margin-left: 0px;
    min-width: 100%;
    max-width: 100%;
  }
}
