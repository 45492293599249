.login {
  background-color: white;
  margin: 24px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.loginForm {
  max-width: 300px;
}

.button {
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  margin: 16px;

  font-weight: bold;
}

@media screen and (max-width: 640px) {
  .loginForm {
    width: 100%;
  }

  .login {
    margin: 8px;
    padding: 8px;
  }
}

@media screen and (max-width: 768px) {
  .loginForm {
    width: 100%;
  }
}
