.sorter {
  margin-bottom: 24px;
  margin-right: 24px;
  margin-left: -20px;
}

.groupSelect {
  width: 300px;
  margin-right: 24px;
}

.filterBox {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media screen and (max-width: 640px) {
  .filterBox {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .groupSelect {
    width: 250px;
  }

  .secondBox {
    padding-left: 26px;
    margin-top: 8px;
  }

  .cancelButton {
    margin-top: 8px;
  }
}
