.layout {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: 24px;
  padding-bottom: 0px;
  flex: 0
}

.title {
  color: #fff;
  margin: 0;
}

.logoBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .title {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .title {
    display: block;
  }

  .header {
    padding: 20px 20px;
  }

  .content {
    padding: 0px;
    padding-bottom: 0px;
  }
}
